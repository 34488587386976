<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="content">
        <div class="row occ-modal-header">
            <h5 class="occ-modal-title" translate>cgu-modal.title</h5>
            <button
                type="button"
                class="col-1 cancel-btn link-custom-focus"
                (click)="cancel()"
                placement="left"
                ngbTooltip="{{ 'Close' | translate }}"
            >
                <span>
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </button>
        </div>

        <div class="row">
            <div class="col">
                <div class="occ-modal-body">
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-1.title</p>
                    <p translate>cgu-modal.sub-section-1.part-one</p>
                    <p translate>cgu-modal.sub-section-1.part-two</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-2.title</p>
                    <p translate>cgu-modal.sub-section-2.conditions-creation.title</p>
                    <ul>
                        <li translate>cgu-modal.sub-section-2.conditions-creation.part-one</li>
                        <li translate>cgu-modal.sub-section-2.conditions-creation.part-two</li>
                        <li translate>cgu-modal.sub-section-2.conditions-creation.part-three</li>
                    </ul>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-3.title</p>
                    <p translate>cgu-modal.sub-section-3.consentement.title</p>
                    <ul>
                        <li translate>cgu-modal.sub-section-3.consentement.part-one</li>
                    </ul>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-4.title</p>
                    <p translate>cgu-modal.sub-section-4.part-one</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-5.title</p>
                    <p translate>cgu-modal.sub-section-5.part-one</p>
                    <p translate>cgu-modal.sub-section-5.part-two</p>
                    <p translate>cgu-modal.sub-section-5.part-three</p>
                    <p translate>cgu-modal.sub-section-5.part-four</p>
                    <p translate>cgu-modal.sub-section-5.part-five</p>
                    <p>
                        <span translate>cgu-modal.sub-section-5.part-six</span>
                        <a href="https://mon-entreprise.laregion.fr">
                            <span translate>cgu-modal.sub-section-5.part-six-link</span>
                        </a>
                    </p>
                    <p translate>cgu-modal.sub-section-5.part-seven</p>
                    <p translate>cgu-modal.sub-section-5.part-eight</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-6.title</p>
                    <p translate>cgu-modal.sub-section-6.part-one</p>
                    <p translate>cgu-modal.sub-section-6.part-two</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-7.title</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-7.infos-editeur.title</p>
                    <p translate>cgu-modal.sub-section-7.infos-editeur.part-one</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-7.donnees-personnelles.title</p>
                    <p translate>cgu-modal.sub-section-7.donnees-personnelles.part-one</p>
                    <p translate>cgu-modal.sub-section-7.donnees-personnelles.part-two</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-7.propriete-intellectuelle.title</p>
                    <p translate>cgu-modal.sub-section-7.propriete-intellectuelle.part-one</p>
                    <p translate>cgu-modal.sub-section-7.propriete-intellectuelle.part-two</p>
                    <p translate>cgu-modal.sub-section-7.propriete-intellectuelle.part-three</p>
                    <p>
                        <span translate>cgu-modal.sub-section-7.propriete-intellectuelle.adress.part-one</span><br />
                        <span translate>cgu-modal.sub-section-7.propriete-intellectuelle.adress.part-two</span><br />
                        <span translate>cgu-modal.sub-section-7.propriete-intellectuelle.adress.part-three</span>
                    </p>
                    <p translate>cgu-modal.sub-section-7.propriete-intellectuelle.part-four</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <button type="button" class="btn float-right" (click)="cancel()" [attr.aria-label]="'Close' | translate" translate>
                    Close
                </button>
            </div>
        </div>
    </div>
</app-modal>
