import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPen, faPlus, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { ModalAccessibilite } from 'src/app/components/modal/accessibilite-modal/accessibilite-modal.component';
import { ModalCgu } from 'src/app/components/modal/cgu-modal/cgu-modal.component';
import { ModalCookies } from 'src/app/components/modal/cookies-modal/cookies-modal.component';
import { ModalPlanDuSite } from 'src/app/components/modal/plandusite-modal/plandusite-modal.component';
import { ModalRgpd } from 'src/app/components/modal/rgpd-modal/rgpd-modal.component';
import { Client } from 'src/app/models/client';
import { SortEntityPipe, SortingType } from 'src/app/pipes/sort-entity.pipe';
import { CheckingAccessService } from 'src/app/services/checking-access/checking-access.service';
import { GroupService } from 'src/app/services/gdi-api/group.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environmentLoader } from 'src/environments/environmentLoader';
import { Entity, EntityTypes } from '../../models/entity';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth/auth.service';
import { AccessService } from '../../services/gdi-api/access.service';
import { IsInternalPageService } from '../../services/is-internal-page.service';
import { Modal, ModalService } from '../../services/modal/modal.service';
import { ProfilCompletionService } from '../../services/profil-completion-service.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    faUserEdit = faUserEdit;
    faPen = faPen;
    faPlus = faPlus;
    companyEntities: Array<Entity> = [];
    projectEntities: Array<Entity> = [];
    entities: Array<Entity>;
    currentUser: User;
    redirectUri: string;
    defaultFormType: string;
    bCheckAffiliation: boolean = false;
    bVerifyIdentity: boolean = false;
    currentEntity: Entity;
    selectedClient: Client;
    bActionRequired: boolean = false;
    useNetheos: boolean = false;

    subscriptions: Subscription[] = [];

    protected keycloakUrl: string;
    protected keycloakRealm: string;
    clients = [
        { name: 'Mes aides en ligne', label: 'pda', url: '', selected: false },
        { name: 'Hub entreprendre', label: 'hubentreprendre', url: '', selected: false },
        { name: 'Adhésion Sud de France', label: 'asdf', url: '', selected: false },
        { name: 'Affiliation Fabriqué En Occitanie', label: 'afeo', url: '', selected: false}
    ];

    constructor(
        private keycloak: KeycloakService,
        private translate: TranslateService,
        private auth: AuthService,
        private modal: ModalService,
        private route: ActivatedRoute,
        private api: GroupService,
        private accessApi: AccessService,
        private router: Router,
        private localStorage: LocalStorageService,
        private isInternalPageService: IsInternalPageService,
        private profileCompletionService: ProfilCompletionService,
        private checkingAccessService: CheckingAccessService,
        private sortEntityPipe: SortEntityPipe
    ) {
        environmentLoader.then(env => {
            this.keycloakUrl = env.settings.auth.keycloak.url;
            this.keycloakRealm = env.settings.auth.keycloak.realm;
            this.useNetheos = env.settings.netheos.enable;
        });
    }

    ngOnInit(): void {
        this.clearStorages();
        this.getUserEntities().then(({ user, entities }) => {
            this.currentUser = user;
            if (entities.length > 0) {
                this.getDefaultSelectedEntity(entities).then(entity => {
                    this.updateSelectedEntity(entity);
                    this.currentEntity = entity;
                });
            }
        });

        const profilSubscription = this.profileCompletionService.user$.subscribe();
        this.subscriptions.push(profilSubscription);

        this.defaultFormType = this.route.snapshot.data['defaultFormType'];
        this.isInternalPageService.changeValue(false);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    getUserEntities(): Promise<{ user: any; entities: Entity[] }> {
        return this.auth.refreshUser().then(({ user, entities }) => {
            this.currentUser = user;
            this.companyEntities = entities.filter(entity => entity.type === EntityTypes.Company);
            this.projectEntities = entities.filter(entity => entity.type === EntityTypes.Project);
            this.checkAllRequirements();
            const transformedProjectEntities = this.getMandatoriesProjects(entities);
            const addProject = new Array<Entity>();
            transformedProjectEntities.forEach(project =>
                addProject.push(new Entity(project.name, EntityTypes.TransformedProject, project.role, project.attributes))
            );
            addProject.forEach(entity => this.projectEntities.push(entity));
            return { user, entities };
        });
    }

    clickEntity($event): void {
        const entity = $event.entity;
        // Initialize globals to handle user required actions
        this.bVerifyIdentity = false;
        this.bCheckAffiliation = false;
        this.currentEntity = $event.entity;
        if (entity) {
            this.modal.hide(ModalCgu);
            this.modal.hide(ModalRgpd);
            this.modal.hide(ModalCookies);
            this.modal.hide(ModalAccessibilite);
            this.modal.hide(ModalPlanDuSite);
            this.modal.hide(ModalCgu);
            this.updateSelectedEntity(entity);
        }
    }

    selectClient($event: Client): void {
        // Initialize globals to handle user required actions
        this.selectedClient = $event;
        const accessInfos = this.checkingAccessService.checkAccessToClientService(this.currentEntity, this.selectedClient);
        if (accessInfos.isAuthorized) {
            if (this.selectedClient.label === 'pda') {
                this.redirectTo();
            } else {
                this.redirectTo(this.currentEntity);
            }
        } else {
            this.openModalAccess(accessInfos.message);
        }
        // setTimeout(() => (this.selectedClient.selected = false), 500);
    }

    goToCompanySiretSearchPage(): void {
        this.localStorage.setProjectId(null);
        this.router.navigate(['entreprise'], {
            queryParams: {
                service: this.route.snapshot.queryParams['service'],
                redirect_uri: this.route.snapshot.queryParams['redirect_uri'] || undefined
            }
        });
    }

    goToProjectSearchPage(): void {
        this.router.navigate(['projet'], {
            queryParams: {
                service: this.route.snapshot.queryParams['service'],
                redirect_uri: this.route.snapshot.queryParams['redirect_uri'] || undefined
            }
        });
    }

    goToEditProfile(): any {
        return `${this.keycloakUrl}/realms/${this.keycloakRealm}/account?referrer=${encodeURIComponent(window.location.href)}`;
    }

    createEntity($event): void {
        const entity = $event.entity;
        const func = entity.type === EntityTypes.Company ? 'joinGroup' : 'createProject';
        this.api[func](entity.name)
            .then(response => {
                if (!response['hasError']) {
                    $event.formReset();
                    this.getUserEntities();
                } else {
                    throw response;
                }
            })
            .catch(error => {
                this.raiseError(error);
            });
    }

    removeEntity($event): void {
        const entity = $event.entity;
        const confirm =
            entity.type === EntityTypes.Company
                ? this.t('confirmLeaveCompany', entity.name).replace('{displayName}', entity.name)
                : this.t('confirmCloseProject', entity);
        this.modal.confirm({
            text: confirm,
            onSuccess: () => {
                this.api
                    .leaveGroup(entity.name)
                    .then(response => {
                        if (!response['hasError']) {
                            this.getUserEntities();
                        } else {
                            throw response;
                        }
                    })
                    .catch(error => {
                        this.raiseError(error);
                    });
            }
        });
    }

    checkCompanyAffiliation($event): void {
        const entity = $event.entity.name;
        this.api
            .checkCompanyAffiliation(entity)
            .then(response => {
                if (response) {
                    // Refresh user token to get updated entity list roles
                    this.getUserEntities();
                    if (response.messages) {
                        if (response.messages._$WorkflowLabel || response.messages._$DossierToken) {
                            this.modal.netheosAssociations({ messages: response.messages, siret: entity });
                        } else if (response.messages && Object.keys(response.messages).length) {
                            this.modal.alert(response.messages[Object.keys(response.messages)[0]]);
                        }
                    }
                }
            })
            .catch(httpErrorResponse => {
                // Catch and display error message
                if (httpErrorResponse.error && httpErrorResponse.status > 400) {
                    this.modal.accessError(this.getProcessedErrorMessage(httpErrorResponse));
                }
            });
    }

    private redirectTo(entity?: Entity): void {
        const redirectUrl = entity
            ? this.accessApi.getRedirectURL(this.selectedClient.label, entity.type, entity.name)
            : this.accessApi.getRedirectURL(this.selectedClient.label);

        redirectUrl
            .then(response => {
                window.location.href = response['redirectLink'];
            })
            .catch(httpErrorResponse => {
                if (httpErrorResponse.error && httpErrorResponse.status > 400) {
                    this.modal.accessError(this.getProcessedErrorMessage(httpErrorResponse));
                    this.profileCompletionService.refreshUser();
                }
            });
    }

    /**
     * We retrieve user information.
     * If the user has a siret within their default_entity
     * This means that he has previously manipulated this company and we must select it by default
     * Otherwise, we select the first company on the list.
     */
    private getDefaultSelectedEntity(entities: Entity[]): Promise<Entity> {
        return this.loadUserInfo().then(infos => {
            const siret = infos.default_entity;
            if (siret) {
                const entity = entities.find(entity => entity.name === siret);
                return entity;
            } else {
                const entitiesOrder = this.sortEntityPipe.transform(entities, SortingType.Asc);
                return entitiesOrder[0];
            }
        });
    }

    private updateSelectedEntity(entity: Entity): void {
        // this.modal.loader(true);
        this.printLoaderModalAndHideOthers();
        this.updateIsSelected(entity, this.companyEntities);
        this.updateIsSelected(entity, this.projectEntities);
        this.accessApi
            .checkAccessForSelectedEntreprise(entity.type, entity.name)
            .then(() => {
                setTimeout(() => this.modal.loader(false), 2000);
            })
            .catch(() => {
                setTimeout(() => this.modal.loader(false), 2000);
            });
    }

    /**
     * Forces updating of the "isSelected" property of the companies/projects table.
     * If the project type AND the name, are equal to the entity being manipulated. Then isSelected is true, otherwise false.
     * @param entitySelected
     * @param entities
     * @returns Le tableau d'entité mis à jour avec la nouvelle selection
     */
    private updateIsSelected(entitySelected: Entity, entities: Entity[]): Entity[] {
        return entities.map(entity => {
            entity.isSelected = entity.name === entitySelected.name && entity.type === entitySelected.type;
            return entity;
        });
    }

    /**
     * Case when user process an affiliation checking after get access error message
     * We want to close accessError modal if all requirements are done
     */
    private checkAllRequirements(): void {
        this.companyEntities.forEach(company => {
            // This entity is the one on which user check his affiliation
            if (this.currentEntity && this.currentEntity.name === company.name) {
                // If check affiliation was required we check if it's done
                if (this.bCheckAffiliation && company.getRoleLabel() !== "Lien non vérifé avec l'entreprise") {
                    this.bCheckAffiliation = false;
                }

                // If check identity verification was required we check if it's done
                if (this.bVerifyIdentity && this.auth.getUserRoles().includes('VERIFIED')) {
                    this.bVerifyIdentity = false;
                }

                // If all requirements are done we close accessError modal
                if (this.bActionRequired && !this.bCheckAffiliation && !this.bVerifyIdentity) {
                    this.modal.hide(Modal.AccessError);
                    this.bActionRequired = this.bVerifyIdentity && this.bCheckAffiliation;
                }
            }
        });
    }

    private getMandatoriesProjects(entities: Entity[]): Entity[] {
        return entities
            .filter(
                entity =>
                    entity.getAttribute('projectId').values[0] &&
                    entity.getAttribute('projectId').values[0] !== null &&
                    entity.getAttribute('projectId').values[0] !== ''
            )
            .filter(
                entity =>
                    entity.getAttribute('projectMandataireId').values[0] &&
                    entity.getAttribute('projectMandataireId').values[0] !== null &&
                    this.currentUser.id === entity.getAttribute('projectMandataireId').values[0]
            );
    }

    private clearStorages(): void {
        this.localStorage.deleteSiret();
        this.localStorage.deleteCompany();
        this.localStorage.deleteProject();
        this.localStorage.deleteProjectId();
        this.localStorage.deleteTransformProject();
        this.localStorage.deleteAccessProject();
    }

    private openModalAccess(error: string) {
        this.modal.accessError(error);
    }

    private raiseError(error): void {
        this.getUserEntities();
        const msg = error.errorMessage || this.t('errorCommon');
        this.modal.alert(msg);
    }

    private getProcessedErrorMessage(httpErrorResponse: HttpErrorResponse): string {
        // Handle differrents error response structure
        let processedErrorMessage = httpErrorResponse.error.error ? httpErrorResponse.error.message : httpErrorResponse.error;

        // Init vars
        this.bVerifyIdentity = false;
        this.bCheckAffiliation = false;

        // Enable identity verification button
        if (processedErrorMessage.indexOf('<br/>checkIdentity') > -1) {
            this.bVerifyIdentity = true;
            this.bActionRequired = true;
            processedErrorMessage = processedErrorMessage.replace('checkIdentity', '');
        }

        // Enable check affiliation button
        if (processedErrorMessage.indexOf('<br/>checkAffiliation') > -1) {
            this.bCheckAffiliation = true;
            this.bActionRequired = true;
            processedErrorMessage = processedErrorMessage.replace('checkAffiliation', '');
        }

        return processedErrorMessage;
    }

    /**
     * Get the user information from keycloak
     * @returns Promise<any>
     */
    private loadUserInfo(): Promise<any> {
        return this.keycloak.getKeycloakInstance().loadUserInfo();
    }

    private printLoaderModalAndHideOthers(): void {
        this.modal.loader(true);
    }
}
