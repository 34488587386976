import { Injectable } from '@angular/core';
import { Client } from 'src/app/models/client';
import { Entity } from 'src/app/models/entity';
import { ClientLabel } from './client-label.enum';

@Injectable()
export class CheckingAccessService {
    constructor() {}

    checkAccessToClientService(entity: Entity, client: Client): any {
        if (client.name === ClientLabel.HubEntreprendre && entity === undefined) {
            return this.buildAccesMessage(false, 'Veuillez sélectionner ou ajouter une entreprise ou un projet pour accéder au service');
        }
        if (
            ((client.name === ClientLabel.AdhesionSudDeFrance || client.name === ClientLabel.Afeo) && entity === undefined) ||
            ((client.name === ClientLabel.AdhesionSudDeFrance || client.name === ClientLabel.Afeo) && !this.isCompany(entity))
        ) {
            return this.buildAccesMessage(false, 'Veuillez selectionner ou ajouter une entreprise pour accéder au service');
        }

        return this.buildAccesMessage(true, `Redirection vers ${client.name}`);
    }

    private buildAccesMessage(authorized: boolean, message: string): any {
        return {
            isAuthorized: authorized,
            message: message
        };
    }

    private isCompany(entity: Entity): boolean {
        return entity.type !== 'company' ? false : true;
    }
}
