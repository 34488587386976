<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="content">
        <div class="row occ-modal-header">
            <h5 class="occ-modal-title" translate>rgpd-modal.title</h5>
            <button
                type="button"
                class="col-1 cancel-btn link-custom-focus"
                (click)="cancel()"
                placement="left"
                ngbTooltip="{{ 'Close' | translate }}"
            >
                <span>
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </button>
        </div>

        <div class="row">
            <div class="col">
                <div class="occ-modal-body">
                    <p class="logo-container"><img alt="logo region occitanie" src="../../../../assets/img/logo.jpg" class="logo" /></p>
                    <p class="sub-title" translate>rgpd-modal.sub-title</p>
                    <div class="sub-sub-title">
                        <p translate>rgpd-modal.sub-sub-title-1</p>
                        <p translate>rgpd-modal.sub-sub-title-2</p>
                    </div>
                    <p translate>rgpd-modal.intro.title</p>
                    <ul>
                        <li translate>rgpd-modal.intro.part-one</li>
                        <li translate>rgpd-modal.intro.part-two</li>
                    </ul>

                    <div class="summary">
                        <p>
                            <a href="#section1">
                                <span translate>rgpd-modal.summary.part-one</span>
                            </a>
                        </p>
                        <p>
                            <a href="#section2">
                                <span translate>rgpd-modal.summary.part-two</span>
                            </a>
                        </p>
                        <p>
                            <a href="#section3">
                                <span translate>rgpd-modal.summary.part-three</span>
                            </a>
                        </p>
                        <p>
                            <a href="#section4">
                                <span translate>rgpd-modal.summary.part-four</span>
                            </a>
                        </p>
                    </div>

                    <h3 id="section1">
                        <span translate>rgpd-modal.summary.part-one</span>
                    </h3>
                    <p>
                        <span translate>rgpd-modal.sub-section-1.intro.part-one</span>
                        <span class="font-weight-bold" translate>rgpd-modal.sub-section-1.intro.part-two</span>
                        <span translate>rgpd-modal.sub-section-1.intro.part-three</span>
                    </p>
                    <p translate>rgpd-modal.sub-section-1.question.title</p>
                    <ul>
                        <li translate>rgpd-modal.sub-section-1.question.question-one</li>
                        <li>
                            <span translate>rgpd-modal.sub-section-1.question.question-two.by-mail</span>
                            <a href="mailto:dpd@laregion.fr">
                                <span translate>rgpd-modal.sub-section-1.question.question-two.mail-link</span>
                            </a>
                            <span translate>rgpd-modal.sub-section-1.question.question-two.personnal-datas</span>
                        </li>
                        <li>
                            <span translate>rgpd-modal.sub-section-1.question.question-three.unsubscribe</span>
                            <a href="mailto:support-ext@laregion.fr">
                                <span translate>rgpd-modal.sub-section-1.question.question-three.unsubscribe-mail</span>
                            </a>
                        </li>
                    </ul>
                    <p translate>rgpd-modal.sub-section-1.other-question.title</p>
                    <ul>
                        <li>
                            <span translate>rgpd-modal.sub-section-1.other-question.question-one.hub-entreprendre</span>
                            <a href="mailto:contact-hub@laregion.fr">
                                <span translate>rgpd-modal.sub-section-1.other-question.question-one.hub-entreprendre-link</span>
                            </a>
                        </li>
                        <li>
                            <span translate>rgpd-modal.sub-section-1.other-question.question-two.cite-economie</span>
                            <a href="mailto:support-ext@laregion.fr">
                                <span translate>rgpd-modal.sub-section-1.other-question.question-two.cite-economie-link</span>
                            </a>
                        </li>
                    </ul>

                    <h3 id="section2">
                        <span translate>
                            rgpd-modal.summary.part-two
                        </span>
                    </h3>
                    <p translate>rgpd-modal.sub-section-2.intro</p>
                    <p class="font-weight-bold" translate>rgpd-modal.sub-section-2.legal-base</p>
                    <p>
                        <span translate>rgpd-modal.sub-section-2.treatment.title.part-one</span>
                        <span class="font-weight-bold" translate>rgpd-modal.sub-section-2.treatment.title.part-two</span>
                        <span translate>rgpd-modal.sub-section-2.treatment.title.part-three</span>
                    </p>
                    <ul>
                        <li translate>rgpd-modal.sub-section-2.treatment.part-one</li>
                        <li translate>rgpd-modal.sub-section-2.treatment.part-two</li>
                        <li translate>rgpd-modal.sub-section-2.treatment.part-three</li>
                        <li translate>rgpd-modal.sub-section-2.treatment.part-four</li>
                        <li translate>rgpd-modal.sub-section-2.treatment.part-five</li>
                        <li translate>rgpd-modal.sub-section-2.treatment.part-six</li>
                    </ul>
                    <p translate>rgpd-modal.sub-section-2.cadre</p>
                    <p>
                        <span class="font-weight-bold" translate>rgpd-modal.sub-section-2.categories.title</span>
                        <span translate>rgpd-modal.sub-section-2.categories.sub-title</span>
                    </p>
                    <ul>
                        <li translate>rgpd-modal.sub-section-2.categories.part-one</li>
                        <li translate>rgpd-modal.sub-section-2.categories.part-two</li>
                        <li translate>rgpd-modal.sub-section-2.categories.part-three</li>
                    </ul>
                    <p class="font-weight-bold" translate>rgpd-modal.sub-section-2.communication.title</p>
                    <p translate>rgpd-modal.sub-section-2.communication.sub-title</p>
                    <ul>
                        <li translate>rgpd-modal.sub-section-2.communication.part-one</li>
                        <li translate>rgpd-modal.sub-section-2.communication.part-two</li>
                    </ul>
                    <p translate>rgpd-modal.sub-section-2.communication.outro</p>
                    <p class="font-weight-bold" translate>rgpd-modal.sub-section-2.duration.title</p>
                    <p translate>rgpd-modal.sub-section-2.duration.conservation.description <span translate>rgpd-modal.sub-section-2.duration.conservation.sub-description</span></p>
                    <ul>
                        <li translate>rgpd-modal.sub-section-2.duration.conservation.part-one</li>
                        <li translate>rgpd-modal.sub-section-2.duration.conservation.part-two</li>
                    </ul>
                    <p translate>rgpd-modal.sub-section-2.duration.anonymized</p>
                    <p translate>rgpd-modal.sub-section-2.duration.datas.title</p>
                    <ul>
                        <li translate>rgpd-modal.sub-section-2.duration.datas.part-one</li>
                        <li translate>rgpd-modal.sub-section-2.duration.datas.part-two</li>
                    </ul>
                    <p translate>rgpd-modal.sub-section-2.duration.image.title</p>
                    <p translate>rgpd-modal.sub-section-2.duration.image.part-one</p>
                    <p translate>rgpd-modal.sub-section-2.duration.image.part-two</p>

                    <h3 id="section3">
                        <span translate>
                            rgpd-modal.summary.part-three
                        </span>
                    </h3>
                    <p translate>rgpd-modal.sub-section-3.part-one</p>
                    <p translate>rgpd-modal.sub-section-3.part-two</p>

                    <h3 id="section4">
                        <span translate>
                            rgpd-modal.summary.part-four
                        </span>
                    </h3>
                    <p translate>rgpd-modal.sub-section-4.intro</p>
                    <ul>
                        <li>
                            <span translate>rgpd-modal.sub-section-4.access.part-one</span>
                            <span class="font-weight-bold" translate>rgpd-modal.sub-section-4.access.part-two</span>
                            <span translate>rgpd-modal.sub-section-4.access.part-three</span>
                        </li>
                        <li>
                            <span translate>rgpd-modal.sub-section-4.remove.part-one</span>
                            <span class="font-weight-bold" translate>rgpd-modal.sub-section-4.remove.part-two</span>
                            <span translate>rgpd-modal.sub-section-4.remove.part-three</span>
                        </li>
                        <li>
                            <span translate>rgpd-modal.sub-section-4.rectify.part-one</span>
                            <span class="font-weight-bold" translate>rgpd-modal.sub-section-4.rectify.part-two</span>
                            <span translate>rgpd-modal.sub-section-4.rectify.part-three</span>
                        </li>
                        <li>
                            <span translate>rgpd-modal.sub-section-4.delete.part-one</span>
                            <span class="font-weight-bold" translate>rgpd-modal.sub-section-4.delete.part-two</span>
                            <span translate>rgpd-modal.sub-section-4.delete.part-three</span>
                        </li>
                        <li>
                            <span translate>rgpd-modal.sub-section-4.limitation.part-one</span>
                            <span class="font-weight-bold" translate>rgpd-modal.sub-section-4.limitation.part-two</span>
                            <span translate>rgpd-modal.sub-section-4.limitation.part-three</span>
                        </li>
                        <li>
                            <span translate>rgpd-modal.sub-section-4.portability.part-one</span>
                            <span class="font-weight-bold" translate>rgpd-modal.sub-section-4.portability.part-two</span>
                            <span translate>rgpd-modal.sub-section-4.portability.part-three</span>
                        </li>
                        <li>
                            <span translate>rgpd-modal.sub-section-4.reclamation.part-one</span>
                            <span class="font-weight-bold" translate>rgpd-modal.sub-section-4.reclamation.part-two</span>
                            <span translate>rgpd-modal.sub-section-4.reclamation.part-three</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <button type="button" class="btn float-right" (click)="cancel()" [attr.aria-label]="'Close' | translate" translate>
                    Close
                </button>
            </div>
        </div>
    </div>
</app-modal>
